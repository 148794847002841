<template>
    <div>
        axad
    </div>
</template>
<script>
export default {
    name: "printedForm",
    data(){
        return {
               
        }
    }
}
</script>